<template>
  <div
    :class="{
      content: true,
      page: page.total > queryParams.Limit,
    }"
    ref="content"
  >
    <div class="main-content">
      <div class="search-box" ref="search">
        <a-form>
          <a-row>
            <a-col :sm="6" :xxl="4">
              <s-select
                title="供货商"
                :labelCol="8"
                :valueCol="14"
                v-model:value="queryParams.SupplierId"
                :options="suppliers"
              />
            </a-col>

            <a-col :sm="6" :xxl="4">
              <s-date-time
                title="开始日期"
                :labelCol="8"
                :valueCol="14"
                v-model:value="queryParams.BeginDate"
              />
            </a-col>
            <a-col :sm="6" :xxl="4">
              <s-date-time
                title="结束日期"
                :labelCol="8"
                :valueCol="14"
                v-model:value="queryParams.EndDate"
              />
            </a-col>

            <a-col :sm="6" :xxl="4">
              <a-button type="primary" @click="getDataList(1)"> 查询 </a-button>
              <a-button
                type="primary"
                @click="reset(() => resetFun())"
                danger
                style="margin-left: 10px"
              >
                重置
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="data-list">
        <a-table
          :columns="columns"
          :data-source="list"
          :pagination="false"
          :loading="loading"
          :row-key="(record) => record.id"
          :scroll="{ y: height, x: width }"
        >
          <template #bodyCell="{ column, record, index }">
            <template v-if="column.key === 'index'">
              {{ (page.page - 1) * queryParams.Limit + index + 1 }}
            </template>

            <template v-if="column.key === 'action'">
              <a-tooltip>
                <template #title>编辑</template>
                <a @click.stop="editForm(() => $refs.eForm.open(record))">
                  <EditOutlined />
                </a>
              </a-tooltip>
              <a-divider type="vertical" />
              <a-tooltip>
                <template #title>删除</template>
                <a @click.stop="delForm(() => delFormFun(record))">
                  <DeleteOutlined />
                </a>
              </a-tooltip>
            </template>
          </template>
        </a-table>
      </div>
    </div>

    <div class="pagination">
      <a-pagination
        :total="page.total"
        v-model:current="page.page"
        v-model:page-size="queryParams.Limit"
        :hide-on-single-page="true"
        :show-size-changer="true"
        :page-size-options="['20', '40', '100', '200']"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
      >
        <template #itemRender="{ page, type }">
          <a v-if="type === 'prev'" class="ant-pagination-item-link">上一页</a>
          <a v-else-if="type === 'next'" class="ant-pagination-item-link">
            下一页
          </a>
          <span v-else>{{ page }}</span>
        </template>
      </a-pagination>
      <span>数据总数：{{ page.total }}</span>
    </div>

    <EForm ref="eForm" @ok="getDataList(1)" />
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import {
  EditOutlined,
  DeleteOutlined
} from '@ant-design/icons-vue'
import { GoodsInClass } from '@/apis/goods'
import page from '@/mixins/page'
import EForm from '@/components/forms/supplier/Form'

const api = new GoodsInClass()
export default defineComponent({
  mixins: [page],
  components: {
    EditOutlined,
    DeleteOutlined,
    EForm
  },
  setup () {
    const loading = ref(false)
    const activeKey = ref(-1)
    const height = ref(500)
    const width = ref(500)
    const userId = ref('')
    const visiblePassword = ref(false)
    const suppliers = ref()

    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 60
      },
      {
        title: '姓名',
        dataIndex: 'name',
        key: 'name',
        width: 250
      },
      {
        title: '手机号',
        dataIndex: 'mobile',
        key: 'mobile',
        width: 120
      },
      {
        title: '金额',
        dataIndex: 'money',
        key: 'money'
      }
    ]
    return {
      activeKey,
      columns,
      loading,
      height,
      width,
      userId,
      visiblePassword,
      suppliers
    }
  },
  created () {
    this.getDataList(1)
    this.onGetAllSuppliers()
  },
  methods: {
    async onGetAllSuppliers () {
      const list =
        this.$store.state.data.ALL_SUPPLIER.length > 0
          ? this.$store.state.data.ALL_SUPPLIER
          : await this.$store.dispatch('GetAllSuppliers')

      const nlist = []

      list.map((e) => {
        nlist.push({
          label: e.name,
          value: e.id
        })
      })
      this.suppliers = nlist
    },
    getDataList (page) {
      this.loading = true
      this.page.page = page
      const q = { ...this.queryParams }

      if (q.BeginDate) {
        q.BeginDate = q.BeginDate.format('YYYY-MM-DD')
      }
      if (q.EndDate) {
        q.EndDate = q.EndDate.format('YYYY-MM-DD')
      }

      api.supplierStat(q).then((resp) => {
        this.list = resp
        this.loading = false
        this.getTableHeight()
      })
    },
    resetFun () {
      this.queryParams = {
        Limit: 20
      }

      this.getDataList(1)
    },

    getTableHeight () {
      const paddingBottom = this.page.total > this.queryParams.Limit ? 50 : 9
      const mainPadding = 30
      const thead = 56
      this.height =
        this.$refs.content.offsetHeight -
        this.$refs.search.offsetHeight -
        thead -
        paddingBottom -
        mainPadding

      this.width = this.$refs.search.offsetWidth - 10
    },
    formOk () {
      this.getDataList(1)
    }
  }
})
</script>

<style lang="less" scoped>
</style>
