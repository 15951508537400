<template>
  <e-modal
    :visible="visible"
    :title="title"
    :width="500"
    @close="onClose"
    @ok="onSubmit((e) => onSubmitFun(e))"
  >
    <a-spin tip="Loading..." :spinning="loading">
      <a-form>
        <e-input
          title="姓名"
          :validate="validateInfos.name"
          v-model:value="modelRef.name"
        />
        <e-input title="手机号" :validate="validateInfos.mobile" v-model:value="modelRef.mobile" />
        <e-textarea title="备注" v-model:value="modelRef.remark" />
      </a-form>
    </a-spin>
  </e-modal>
</template>

<script>
import { defineComponent, ref, reactive, toRaw } from 'vue'
import { Form } from 'ant-design-vue'
import form from '@/mixins/form'
import { SupplierClass } from '@/apis/supplier'

const useForm = Form.useForm
const api = new SupplierClass()
export default defineComponent({
  mixins: [form],
  emits: ['ok'],
  props: {
    allowRoles: {
      type: Array,
      default: () => []
    }
  },
  setup (props, { emit }) {
    const loading = ref(false)
    const visible = ref(false)
    const isEdit = ref(false)
    const title = ref('')

    const modelRef = reactive(api.modelRef)
    const rulesRef = reactive(api.rulesRef)

    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef
    )

    const onClose = () => {
      resetFields()
      loading.value = false
      visible.value = false
    }

    return {
      title,
      visible,
      loading,
      isEdit,
      modelRef,
      validateInfos,
      resetFields,
      validate,
      onClose
    }
  },
  methods: {
    open (formData) {
      if (formData) {
        this.modelRef.remark = formData.remark
        this.modelRef.mobile = formData.mobile
        this.modelRef.name = formData.name
        this.modelRef.id = formData.id
        this.title = '编辑：' + formData.name
        this.isEdit = true
      } else {
        this.modelRef = reactive(api.modelRef)
        this.title = '添加供货商'
        this.isEdit = false
      }

      this.visible = true
    },

    onSubmitFun (e) {
      const data = toRaw(e)
      if (this.isEdit) {
        api
          .update(data)
          .then(() => {
            this.loading = false
            this.$message.success('编辑成功')
            this.$emit('ok', data)
            this.onClose()
          })
          .catch(() => {
            this.loading = false
          })
      } else {
        api
          .create(data)
          .then(() => {
            this.loading = false
            this.$message.success('添加成功')
            this.$emit('ok', data)
            this.onClose()
          })
          .catch(() => {
            this.loading = false
          })
      }
    }
  }
})
</script>
